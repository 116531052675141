<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>场景服务</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container">
      <div class="box">
        <div class="container_title">教育场景 --- K12</div>
        <div class="container_content">
          <p>
            通过生物识别技术，教学应用覆盖全体教师、学习应用覆盖全体适龄学生、数字校园建设覆盖全体学校，信息化应用水平和师生信息素养普遍提高，建成“互联网+教育”大平台。
          </p>
          <div class="s_container">
            <div class="s_title">亲情话机</div>
            <div class="img">
              <img src="../../../assets/img/home/detail/cjfwU7.png" alt="" />
            </div>
            <div class="img">
              <img src="../../../assets/img/home/detail/cjfwU8.png" alt="" />
            </div>
            <div class="s_title">校园刷脸代扣</div>
            <p>
              即刷即走，无需等待。学生（教职工）使用刷脸支付就餐一人一脸杜绝了逃单，职工卡给人代刷等现象，保护经营者利益。家长能够获取到孩子食堂就餐信息、膳食报告等。学校能追溯食品安全溯源、菜品信息等自动化管理。
            </p>
            <ul>
              <li>产品优势：</li>
              <li>极速支付排队少</li>
              <li>人脸识别仅需300ms</li>
              <li>刷脸取餐走人、无需等待</li>
              <li>后台异步完成扣款</li>
              <li>设备断网可用，刷脸订单不丢失</li>
              <li>离线扣款，平台先进行垫资结算</li>
            </ul>
            <div class="img">
              <img src="../../../assets/img/home/detail/cjfwU12.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="container_title">团餐场景 --- 企业团餐</div>
        <div class="container_content">
          <p>
            将计算机、大数据、物联网等新技术应用于传统食堂，实现食堂信息化、智慧化。智慧之处体现在用户就餐、食堂管理、数据管理、场景管理、智能硬件等多个方面。通过生物识别技术，就餐人员无需携带饭卡，识别速度快，操作简单便捷，仅凭人脸便可轻松通过人脸识别进行快速结算的功能，给智慧食堂提升了超高的效率。
          </p>
          <div class="img">
            <img src="../../../assets/img/home/detail/cjfwU16.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="subInfo">立即体验</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
  padding: 60px;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.container_content {
  margin-top: 30px;
}

.container_content p {
  font-size: 18px;
  color: #333;
  text-align: justify;
  text-indent: 2em;
}

.s_container {
  margin-top: 30px;
}

.s_title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.s_container p {
  font-size: 18px;
  color: #333;
  text-align: justify;
  text-indent: 2em;
  margin-top: 20px;
}

.s_container ul {
  margin-top: 10px;
}

.s_container ul li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
  padding-left: 2em;
}

.img {
  width: 100%;
  padding: 20px;
}

.img img {
  display: block;
  width: 100%;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}
</style>
